import './new-saleperson.scss';
import Header from '../../../organisms/header/header.js';
import { Link, Navigate, useParams, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import decodeToken from '../../../../utils/decode-token';
import { Alert, Snackbar } from '@mui/material';
import Grid from "@mui/material/Unstable_Grid2";
import Input from "../../../atoms/input";
import apiClient from "../../../../utils/apiClient";
import { useForm } from 'react-hook-form';
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";

function NewSaleperson() {
  const { t } = useTranslation();
  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;
  const { retailerId } = useParams();
  const navigate = useNavigate();
  const [areaCode, setAreaCode] = useState('');
  const [countries, setCountries] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Esquema de validación con Joi
  const schema = Joi.object({
    name: Joi.string().required().messages({ 'string.empty': t('alert-name') }),
    last_name: Joi.string().required().messages({ 'string.empty': t('alert-last-name') }),
    email: Joi.string().email({ tlds: { allow: false } }).required().messages({
      'string.email': t('alert-email-format'),
      'string.empty': t('alert-email'),
    }),
    prefix_phone: Joi.string().pattern(/^\+[0-9]{2}$/).required().messages({
      'string.pattern.base': t('alert-prefix'),
      'string.empty': t('alert-prefix'),
    }),
    phone: Joi.string().pattern(/^[0-9]{9}$/).required().messages({
      'string.pattern.base': t('alert-phone-format'),
      'string.empty': t('alert-phone'),
    }),
    preferred_language: Joi.string().required().messages({ 'string.empty': t('alert-language') }),
  });

  // Uso de react-hook-form y Joi para manejar y validar el formulario
  const { register, handleSubmit, formState } = useForm({
    resolver: joiResolver(schema),
    mode: 'onBlur'
  });
  const { errors } = formState;

  // Llamada para obtener el área_code del retailer
  useEffect(() => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_API_URL}/retailer?retailerId=${retailerId}`;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Cache-Control': 'no-cache',
        },
      };

      try {
        const response = await apiClient.get(url, config);
        if (response.status === 200 && response.data.retailer.area_code) {
          setAreaCode(response.data.retailer.area_code);
        }
      } catch (error) {
        setSnackbarOpen(true);
        setSnackbarSeverity('error');
        setSnackbarMessage(t('common-internal-server-error'));
      }
    };

    fetchData();
  }, [retailerId, token, t]);

  // Llamada para obtener los países y establecer el estado de countries
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await apiClient.get(`${process.env.REACT_APP_API_URL}/hac/countries`);
        setCountries(response.data);
      } catch (error) {
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        setSnackbarMessage(t('common-internal-server-error'));
      }
    };
    fetchCountries();
  }, [t]);

  // Obtiene los idiomas basados en el areaCode cuando se actualizan countries o areaCode
  useEffect(() => {
    if (countries.length && areaCode) {
      const country = countries.find((country) => country.area_code === areaCode);
      if (country && country.language) {
        setLanguages(country.language);
      }
    }
  }, [countries, areaCode]);

  // Manejo del envío de formulario con validación
  const onSubmit = async (data) => {
    const payload = [
      {
        email: data.email,
        name: data.name,
        last_name: data.last_name,
        prefix_phone: data.prefix_phone,
        phone: data.phone,
        id_retailer: retailerId,
        preferred_language: data.preferred_language
      }
    ];

    try {
      const response = await apiClient.post(
        `${process.env.REACT_APP_API_URL}/retailer/retailer-salesperson`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        setSnackbarMessage("Salesperson creado correctamente");
        setSnackbarOpen(true);
        setSnackbarSeverity('success');
        setTimeout(() => navigate("/admin/main/retailer"), 2000);
      }
    } catch (error) {
      if (error.response.status === 409) {
        setSnackbarMessage(t('alert-duplicate'));
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      } else {
        setSnackbarSeverity('error');
        setSnackbarMessage(t('common-internal-server-error'));
        setSnackbarOpen(true);
      }
    }
  };

  return (
    <>
      {token && (role === 'admin' || role === 'marketAdmin' || role === 'countryAdmin') ? (
        <div className="App">
          <Header/>
          <main>
            <section className="form">
              <div className="inner">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Link
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(-1);
                    }}
                    className="back-button"
                  >
                    <span className="icon-chevron-left"></span>{' '}
                    {t('common-back')}
                  </Link>
                  <h2>
                    {t('admin-new-saleperson-title')}
                    <small>{t('admin-new-saleperson-text')}</small>
                  </h2>

                  <Grid container spacing={3}>
                    <Grid xs={12} sm={6} md={5}>
                      <div className="form-control required">
                        <Input
                          maxLength="50"
                          type="text"
                          id="name"
                          label={t('common-name')}
                          error={errors?.name}
                          {...register('name')}
                        />
                      </div>
                    </Grid>
                    <Grid xs={12} sm={6} md={5}>
                      <div className="form-control required">
                        <Input
                          maxLength="50"
                          type="text"
                          id="last_name"
                          label={t('common-last-name')}
                          error={errors?.last_name}
                          {...register('last_name')}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid xs={12} sm={12} md={6}>
                      <div className="form-control required">
                        <Input
                          maxLength="50"
                          type="text"
                          id="email"
                          label={t('common-email')}
                          error={errors?.email}
                          {...register('email')}
                        />
                      </div>
                    </Grid>
                    <Grid xs={12} sm={12} md={6}>
                      <div>
                        <Grid container spacing={1}>
                          <Grid xs={4} sm={4} md={4}>
                            <div className="form-control">
                              <Input
                                type="text"
                                id="prefix_phone"
                                placeholder="+xx/+xxx"
                                label={t('common-prefix')}
                                error={errors?.prefix_phone}
                                {...register('prefix_phone')}
                              />
                            </div>
                          </Grid>
                          <Grid xs={8} sm={8} md={8}>
                            <div className="form-control required">
                              <Input
                                type="text"
                                id="phone"
                                label={t('common-phone')}
                                error={errors?.phone}
                                {...register('phone')}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid xs={12} sm={1} md={6}>
                      <div className="form-control required">
                        <label htmlFor="preferred_language">{t('common-select-language')}</label>
                        <select
                          name="preferred_language"
                          id="preferred_language"
                          className="normal"
                          {...register('preferred_language')}
                        >
                          <option value="">{t('common-select-language')}</option>
                          {languages.map((language, index) => (
                            <option key={index} value={language}>
                              {t(`languages-${language}`)}
                            </option>
                          ))}
                        </select>
                        {errors.preferred_language && (
                          <span className="input__error">{errors.preferred_language.message}</span>
                        )}
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid xs={12} sm={6} md={6}>
                      <button className="btn" type="submit">
                        {t('admin-new-saleperson-action')}
                      </button>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </section>
          </main>
          <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
            <Alert severity={snackbarSeverity} sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </div>
      ) : (
        <Navigate to="/login"/>
      )}
    </>
  );
}

export default NewSaleperson;
