import './pma-info.scss';
import Header from '../../../organisms/header/header.js';
import {Link, Navigate, useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import decodeToken from '../../../../utils/decode-token';
import apiClient from '../../../../utils/apiClient';
import {Alert, Snackbar} from '@mui/material';

function PmaInfo() {
  const {t} = useTranslation();
  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;
  const {id} = useParams();

  const [contactInfo, setContactInfo] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const authToken = token;
      const url = `${process.env.REACT_APP_API_URL}/pma/contact-info/hac/${id}`;

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Cache-Control': 'no-cache',
        },
      };

      try {
        const response = await apiClient.get(url, config);
        if (response.status === 200) {
          setContactInfo(response.data);
        }
      } catch (error) {
        if (error.response) {
          setSnackbarOpen(true);
          setSnackbarMessage(t('common-internal-server-error'), error);
        }
      }
    };

    fetchData();
  }, [id, token, t]);

  return (
    <>
      {token && role === 'pma' ? (
        <div className="App">
          <Header/>
          <main>
            <section className="contact-info">
              <div className="inner">
                <Link to="/pma/main" className="back-button">
                  {' '}
                  <span className="icon-chevron-left"></span>{' '}
                  {t('common-back')}
                </Link>
                <h2>{t('pma-info-title')}</h2>
                {contactInfo ? (
                  <>
                    {contactInfo.first_pma && (
                      <div>
                        <h5>{t('pma-info-title-pma1')}</h5>
                        <div className="person">
                          <dl>
                            <div>
                              <dt>{t('common-name')}</dt>
                              <dd>{contactInfo.first_pma.name || 'NA'} {contactInfo.first_pma.last_name || 'NA'} </dd>
                            </div>
                            <div>
                              <dt>{t('common-email')}</dt>
                              <dd>{contactInfo.first_pma.email || 'N/A'}</dd>
                            </div>
                            <div>
                              <dt>{t('common-phone')}</dt>
                              <dd>
                                {contactInfo.first_pma.prefix_phone || ''}{' '}
                                {contactInfo.first_pma.phone || 'N/A'}
                              </dd>
                            </div>
                            <div>
                              <dt>{t('common-country')}</dt>
                              <dd>{t(`countries-${contactInfo.first_pma.area_code}`)}</dd>
                            </div>
                            <div>
                              <dt>{t('common-city')}</dt>
                              <dd>{contactInfo.first_pma.city || 'N/A'}</dd>
                            </div>
                          </dl>
                        </div>
                      </div>
                    )}
                    {contactInfo.second_pma && (
                      <div className="person">
                        <h5>{t('pma-info-title-pma2')}</h5>
                        <dl>
                          <div>
                            <dt>{t('common-name')}</dt>
                            <dd>{contactInfo.second_pma.name || 'N/A'} {contactInfo.second_pma.last_name || 'N/A'} </dd>
                          </div>
                          <div>
                            <dt>{t('common-email')}</dt>
                            <dd>{contactInfo.second_pma.email || 'N/A'}</dd>
                          </div>
                          <div>
                            <dt>{t('common-phone')}</dt>
                            <dd>
                              {contactInfo.second_pma.prefix_phone || ''}{' '}
                              {contactInfo.second_pma.phone || 'N/A'}
                            </dd>
                          </div>
                          <div>
                            <dt>{t('common-country')}</dt>
                            <dd>{t(`countries-${contactInfo.second_pma.area_code}`)}</dd>
                          </div>
                          <div>
                            <dt>{t('common-city')}</dt>
                            <dd>{contactInfo.second_pma.city || 'N/A'}</dd>
                          </div>
                        </dl>
                      </div>
                    )}
                  </>
                ) : (
                  <div>{t('common-loading') + '...'}</div>
                )}
              </div>
            </section>
          </main>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
          >
            <Alert severity="error" sx={{width: '100%'}}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </div>
      ) : (
        <Navigate to="/login"/>
      )}
    </>
  );
}

export default PmaInfo;
