import './retailer-details.scss';
import Header from '../../../organisms/header/header.js';
import {Link, Navigate, useNavigate, useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import decodeToken from '../../../../utils/decode-token';
import axios from 'axios';
import {Alert, Snackbar} from '@mui/material';

function RetailerDetails() {
  const {t} = useTranslation();
  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;
  const {retailerId} = useParams();
  const navigate = useNavigate();
  const [areaCode, setAreaCode] = useState('');
  const [retailerName, setRetailerName] = useState('');

  const [contactInfo, setContactInfo] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const authToken = token;
      const url = `${process.env.REACT_APP_API_URL}/retailer?retailerId=${retailerId}`;

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Cache-Control': 'no-cache',
        },
      };

      try {
        const response = await axios.get(url, config);
        if (response.status === 200) {
          setContactInfo(response.data);
          console.log(contactInfo.retailer_gm)
          setAreaCode(response.data.area_code)
        }
      } catch (error) {
        if (error.response) {
          setSnackbarOpen(true);
          setSnackbarMessage(t('common-internal-server-error'), error);
        }
      }
    };

    fetchData();
  }, [retailerId, token, t]);

  return (
    <>
      {token &&
      (role === 'admin' ||
        role === 'marketAdmin' ||
        role === 'countryAdmin') ? (
        <div className="App">
          <Header/>
          <main>
            <section className="contact-info admin">
              <div className="inner">
                <div className="back-button">
                  <Link to="/admin/main/retailer" className="back-button">
                    <span className="icon-chevron-left"></span>
                    {' '}
                    {t('common-back')}
                  </Link>
                  {' '} / {contactInfo?.retailer.name}
                </div>
                <h2>{t('retailer-info-title')}</h2>
                {contactInfo ? (
                  <>
                    <div className="retailer">
                      <h5>{t('retailer-info-title')}</h5>
                      <dl>
                        <div>
                          <dt>{t('common-name')}</dt>
                          <dd>{contactInfo.retailer.name}</dd>
                        </div>
                        <div>
                          <dt>{t('common-country')}</dt>
                          <dd>{t(`countries-${contactInfo.retailer.area_code}`)}</dd>
                        </div>
                        <div>
                          <dt>{t('common-city')}</dt>
                          <dd>{contactInfo.retailer.city}</dd>
                        </div>
                        <div>
                          <dt>{t('common-retailer-code')}</dt>
                          <dd>{contactInfo.retailer.retailer_code}</dd>
                        </div>
                      </dl>
                    </div>

                    <div className="actions">
                      {!contactInfo.retailer_gm.id && (
                      <button
                        onClick={() =>
                          navigate(`/admin/new-retailerGM/${retailerId}`, {
                            state: {
                              areaCode: areaCode,
                            },
                          })
                        }
                        className="btn secondary"
                      >
                        <span className="icon-plus"></span> {t('admin-new-retailerGM-action')}
                      </button>
                      )}
                        <button
                          onClick={() =>
                            navigate(`/admin/new-saleperson/${retailerId}`, {
                              state: {
                                areaCode: areaCode,
                                retailerName: contactInfo.retailer.name,
                              },
                            })
                          }
                          className="btn secondary"
                        >
                          <span className="icon-plus"></span> {t('admin-new-saleperson-action')}
                        </button>


                    </div>

                    {contactInfo.retailer_gm.name && (
                      <div>
                        <h5>{t('retailer-info-title-retailergm')}</h5>
                        <div className="person">

                          <dl>
                            <div>
                              <dt>{t('common-name')}</dt>
                              <dd>
                                {contactInfo.retailer_gm.name}{' '}
                                {contactInfo.retailer_gm.last_name}
                              </dd>
                            </div>
                            <div>
                              <dt>{t('common-email')}</dt>
                              <dd>{contactInfo.retailer_gm.email}</dd>
                            </div>
                            <div>
                              <dt>{t('common-phone')}</dt>
                              <dd>
                                {contactInfo.retailer_gm.prefix_phone}{' '}
                                {contactInfo.retailer_gm.phone}
                              </dd>
                            </div>
                          </dl>
                        </div>
                      </div>
                    )}

                    {contactInfo.sales_person &&
                      contactInfo.sales_person.length > 0 && (
                        <>
                          <h5>{t('retailer-info-title-saleperson1')}</h5>

                          {contactInfo.sales_person.map((person) => (
                            <div className="person">
                              <dl key={person.id}>
                                <div>
                                  <dt>{t('common-name')}</dt>
                                  <dd>{`${person.name} ${person.last_name}`}</dd>
                                </div>
                                <div>
                                  <dt>{t('common-email')}</dt>
                                  <dd>{person.email}</dd>
                                </div>
                                <div>
                                  <dt>{t('common-phone')}</dt>
                                  <dd>{`${person.prefix_phone} ${person.phone}`}</dd>
                                </div>
                              </dl>
                              <div className="actions">
                                <button
                                  onClick={() =>
                                    navigate(`/admin/edit-saleperson/${retailerId}`)
                                  }
                                  className="btn secondary"
                                >
                                  <span className="icon-plus"></span> {t('common-edit')}
                                </button>
                              </div>
                            </div>
                          ))}

                        </>
                      )}
                  </>
                ) : (
                  <div>Loading...</div>
                )}
              </div>
            </section>
          </main>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
          >
            <Alert severity="error" sx={{width: '100%'}}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </div>
      ) : (
        <Navigate to="/login"/>
      )}
    </>
  );
}

export default RetailerDetails;
