import React, {useEffect, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import apiClient from '../../../../utils/apiClient';
import {Link, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Alert, Snackbar} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import decodeToken from '../../../../utils/decode-token';
import Menu from '@mui/material/Menu';
import Grid from '@mui/material/Unstable_Grid2';

function TableHacExited({reload, onDataLoaded}) {
  const {t} = useTranslation();
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const [retailerOptions, setRetailerOptions] = useState([]);
  const [selectedRetailerId, setSelectedRetailerId] = useState('');
  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleClose = () => {
    setAnchorEl({});
  };

  const handleClick = (id) => (event) => {
    setAnchorEl({...anchorEl, [id]: event.currentTarget});
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const authToken = token;
      const url = `${process.env.REACT_APP_API_URL}/hac/retailer-salesperson?status=exited`;

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Cache-Control': 'no-cache',
        },
      };

      try {
        const response = await apiClient.get(url, config);
        if (response.status === 200) {
          const sortedRows = response.data.sort((a, b) => {
            const [dayA, monthA, yearA] = a.last_update.split('-').map(Number);
            const [dayB, monthB, yearB] = b.last_update.split('-').map(Number);
            const dateA = new Date(yearA, monthA - 1, dayA);
            const dateB = new Date(yearB, monthB - 1, dayB);
            return dateB - dateA;
          });

          const storedRetailerId = sessionStorage.getItem('filter-retailer');
          if (storedRetailerId) {
            const filtered = sortedRows.filter(row => row.retailer_info.id === Number(storedRetailerId));
            setFilteredRows(filtered);
            setSelectedRetailerId(storedRetailerId);
          } else {
            setFilteredRows(sortedRows);
          }

          setRows(sortedRows);
          onDataLoaded();
        } else if (response.status === 204) {
          setRows([]);
          setFilteredRows([]);
        }
      } catch (error) {
        if (error.response) {
          setSnackbarOpen(true);
          setSnackbarMessage(t('common-internal-server-error'), error);
        }
      } finally {
        setDataLoaded(true);
      }

      const userApiUrl = `${process.env.REACT_APP_API_URL}/retailer/retailer-salesperson/${decodeToken(token).id}`;
      try {
        const userResponse = await apiClient.get(userApiUrl, config);
        setRetailerOptions(userResponse.data.retailerInfo || []);
      } catch (error) {
        console.error('Error al obtener datos del usuario:', error);
      }
    };

    if (reload) {
      fetchData();
    }
  }, [reload, token, onDataLoaded, t]);

  const handleApplyFilter = () => {
    sessionStorage.setItem('filter-retailer', selectedRetailerId);
    if (selectedRetailerId) {
      const filtered = rows.filter(row => row.retailer_info.id === Number(selectedRetailerId));
      setFilteredRows(filtered);
    } else {
      setFilteredRows(rows);
    }
  };

  return (
    <>
      {dataLoaded ? (
        rows && rows.length !== 0 ? (
          <div>
            <div className="intro">
              <div className="text">
                <h4>{t('retailer-main-exited-title')}</h4>
                <p>{t('retailer-main-exited-text')}</p>
              </div>
              <div className="actions">
                <Link to="/retailer/nominate-hac" className="btn small">
                  <span className="icon-plus"></span>{' '}
                  {t('retailer-main-active-action')}
                </Link>
              </div>
            </div>

            {role === 'retailerGM' && (
              <div className="filters">
                <p>{t('common-search')}</p>
                <Grid container spacing={3}>
                  <Grid xs={12} sm={10} md={4}>
                    <div className="form-control">
                      <label htmlFor="retailer">{t('common-retailer')}</label>
                      <select
                        name="retailer"
                        id="retailer"
                        className="normal"
                        value={selectedRetailerId}
                        onChange={(e) => setSelectedRetailerId(e.target.value)}
                      >
                        <option value="">{t('common-all-retailers')}</option>
                        {retailerOptions.map((retailer) => (
                          <option key={retailer.id} value={retailer.id}>
                            {retailer.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Grid>
{/*                  <Grid xs={12} sm={2} md={2}>
                    <div className="actions">
                      <button className="btn" onClick={handleApplyFilter}>
                        {t('common-apply')}
                      </button>
                    </div>
                  </Grid>*/}
                </Grid>
              </div>
            )}
            {filteredRows.length !== 0 ? (
              <div className="table-wrapper">
                <Table aria-label="simple table" className="user-table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">{t('common-name')}</TableCell>
                      <TableCell align="left">{t('common-last-name')}</TableCell>
                      <TableCell align="center">{t('common-phone')}</TableCell>
                      <TableCell align="center">{t('common-pma')}</TableCell>
                      {role === 'retailerGM' && (
                        <TableCell align="center">{t('common-retailer')}</TableCell>
                      )}
                      <TableCell align="center">{t('common-last-update')}</TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {filteredRows.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell align="left">{row.name}</TableCell>
                        <TableCell align="left">{row.last_name}</TableCell>
                        <TableCell align="center">
                          {row.prefix_phone} {row.phone}
                        </TableCell>
                        <TableCell align="center">{row.pma}</TableCell>
                        {role === 'retailerGM' && (
                          <TableCell align="center">{row.retailer_info.name} ({row.retailer_info.city})</TableCell>
                        )}
                        <TableCell align="center">{row.last_update}</TableCell>
                        <TableCell align="right">
                          <span
                            className="icon-more"
                            aria-haspopup="true"
                            aria-expanded={Boolean(anchorEl[row.id]) ? 'true' : undefined}
                            onClick={handleClick(row.id)}
                          ></span>
                          <Menu
                            className="submenu"
                            id={`${row.id}`}
                            anchorEl={anchorEl[row.id]}
                            open={Boolean(anchorEl[row.id])}
                            onClose={handleClose}
                            MenuListProps={{'aria-labelledby': 'basic-button'}}
                          >
                            <MenuItem
                              onClick={row.status !== 'exited' ? () => navigate(`/retailer/hac-profile/${row.id}`) : null}
                              className={row.status === 'exited' ? 'disabled' : ''}>
                              {t('common-profile')}
                            </MenuItem>
                            <MenuItem
                              onClick={row.status !== 'exited' ? () => navigate(`/retailer/vehicles/${row.id}`) : null}
                              className={row.status === 'exited' ? 'disabled' : ''}>
                              {t('pma-hac-tab-option2')}
                            </MenuItem>
                            <MenuItem
                              onClick={row.status !== 'exited' ? () => navigate(`/retailer/preferences/${row.id}`) : null}
                              className={row.status === 'exited' ? 'disabled' : ''}>
                              {t('pma-hac-tab-option3')}
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    ))}

                  </TableBody>
                </Table>
              </div>
            ) : (
              <div className="no-result">
                <h2 className="txt-center">
                  <small>{t('admin-main-tab1-text-empty')}</small>
                </h2>
              </div>
            )}
          </div>
        ) : (
          <div className="no-result">
            <h2 className="txt-center">
              {t('retailer-main-exited-empty-title')}
              <small>{t('retailer-main-exited-empty-text')}</small>
            </h2>
            <Link to="/retailer/nominate-hac" className="btn fit">
              <span className="icon-plus"></span>{' '}
              {t('retailer-main-active-action')}
            </Link>
          </div>
        )
      ) : null}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert severity="error" sx={{width: '100%'}}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default TableHacExited;
